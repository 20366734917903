<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('elearning_tim.pre_post_evaluation') }}
              <slot v-if="$route.query.id">
                {{ $t('globalTrans.update') }}
              </slot>
              <slot v-else>
                {{ $t('globalTrans.entry') }}
              </slot>
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="evaluation" :class="'btn btn-success text-light'">{{ $t('elearning_tim.pre_post_evaluation') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Evaluation Type" vid="exam_type" rules="required">
                            <div
                                slot-scope="{ valid, errors }"
                                class="row"
                            >
                              <label for="" class="col-md-4">{{ $t('elearning_tim.evaluation_type') }}</label>
                              <div class="col">
                                <b-form-radio-group
                                    v-model="formData.exam_type"
                                    :disabled="$route.query.id"
                                    :options="examTypeList"
                                    class="mb-3"
                                ></b-form-radio-group>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </div>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <!-- <b-form-select
                                  plain
                                  v-model="formData.fiscal_year_id"
                                  :disabled="itemShowDis"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select> -->
                                  <v-select
                                      id="fiscal_year_id"
                                      v-model="formData.fiscal_year_id"
                                      :disabled="itemShowDis"
                                      :reduce="op => op.value"
                                      :options="fiscalYearList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6" v-if="$store.state.Auth.activeRoleId === 1">
                          <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                              </template>
                              <!-- <b-form-select
                                  plain
                                  v-model="formData.org_id"
                                  :disabled="itemShowDis"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select> -->
                                  <v-select
                                      id="org_id"
                                      v-model="formData.org_id"
                                      :disabled="itemShowDis"
                                      :reduce="op => op.value"
                                      :options="orgList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                              </template>
                              <!-- <b-form-select
                                plain
                                v-model="formData.training_type_id"
                                :disabled="itemShowDis"
                                :options="trainingTypeList"
                                id="training_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select> -->
                                  <v-select
                                      id="training_type_id"
                                      v-model="formData.training_type_id"
                                      :disabled="itemShowDis"
                                      :reduce="op => op.value"
                                      :options="trainingTypeList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_category_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                              </template>
                              <!-- <b-form-select
                                plain
                                v-model="formData.training_category_id"
                                :disabled="itemShowDis"
                                :options="trainingCategoryList"
                                id="training_category_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select> -->
                                  <v-select
                                      id="training_category_id"
                                      v-model="formData.training_category_id"
                                      :disabled="itemShowDis"
                                      :reduce="op => op.value"
                                      :options="trainingCategoryList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_title_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                              </template>
                              <!-- <b-form-select
                                plain
                                v-model="formData.training_title_id"
                                :disabled="itemShowDis"
                                :options="trainingTitleList"
                                id="training_title_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select> -->
                                  <v-select
                                      id="training_title_id"
                                      v-model="formData.training_title_id"
                                      :disabled="itemShowDis"
                                      :reduce="op => op.value"
                                      :options="trainingTitleList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Batch No" vid="batch_no" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="batch_no"
                            >
                            <template v-slot:label>
                              {{$t('elearning_iabm.batch_no')}}
                            </template>
                              <b-form-select
                                plain
                                v-model="formData.batch_no"
                                :disabled="itemShowDis"
                                :options="batchList"
                                id="batch_no"
                                >
                                <template v-slot:first>
                                     <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="circular_memo_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.circular_memo_no"
                                  :disabled="itemShowDis"
                                  :options="circularList"
                                  id="circular_memo_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="EValuation Date"  vid="exam_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="exam_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_tim.evaluation_date') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  class="fromDate"
                                  id="exam_date"
                                  v-model="formData.exam_date"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :class="errors[0] ? 'is-invalid': ''"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="EValuation Time Start"  vid="exam_time_start" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="exam_time_start"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_tim.evaluation_start_time') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  type="time"
                                  id="exam_time_start"
                                  v-model="formData.exam_time_start"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :placeholder="$t('elearning_tim.exam_time_start')"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="EValuation End Time"  vid="exam_time_end" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="exam_time_end"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_tim.evaluation_end_time')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  type="time"
                                  id="exam_time_end"
                                  v-model="formData.exam_time_end"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :placeholder="$t('elearning_tim.exam_time_end')"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Total Mark"  vid="total_mark" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="total_marks"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_tim.total_marks')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="total_mark"
                                  disabled
                                  v-model="formData.total_mark"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :placeholder="$t('elearning_tim.total_marks')"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6" v-if="formData.exam_type === 2 && formData.circular_memo_no && !($route.query.id)">
                          <b-form-checkbox
                            id="checkbox-1"
                            v-model="formData.same_pre_evaluation"
                            name="checkbox-1"
                            value="1"
                            unchecked-value="0"
                            class="checkboxes"
                          >
                            {{ $t('elearning_tim.same_pre_evaluation') }}
                          </b-form-checkbox>
                        </b-col>
                      </b-row>
                      <b-row v-for="(evaluationQuestion, questionIndex) in formData.evaluation_questions" :key="questionIndex" v-show="itemShow">
                        <div class="col-md-12">
                          <fieldset class="p-2 w-100">
                            <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tim.question')}} {{ $n(questionIndex+1) }}</legend>
                            <div class="px-3">
                              <b-row>
                                <b-col lg="6" sm="6">
                                  <ValidationProvider name="Question Type" :vid="'question_type' + (questionIndex+1)" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label-for="'question_type' + (questionIndex+1)"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('elearning_tim.question_type') }} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                          plain
                                          v-model="evaluationQuestion.question_type"
                                          :options="questionTypeList"
                                          :id="'question_type' + (questionIndex+1)"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                        <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col lg="6" sm="6">
                                  <ValidationProvider name="Question"  :vid="'question_' + (questionIndex+1)" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label-for="'question_' + (questionIndex+1)"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('elearning_tim.question_en') }} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                          :id="'question_' + (questionIndex+1)"
                                          v-model="evaluationQuestion.question"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :placeholder="$t('elearning_tim.question_en')"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="6">
                                  <ValidationProvider name="Question Bn"  :vid="'question_bn_' + (questionIndex+1)" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label-for="'question_bn_' + (questionIndex+1)"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('elearning_tim.question_bn') }} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                          :id="'question_bn_' + (questionIndex+1)"
                                          v-model="evaluationQuestion.question_bn"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :placeholder="$t('elearning_tim.question_bn')"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col lg="6" sm="6">
                                  <ValidationProvider name="Description"  :vid="'description_' + (questionIndex+1)" rules="">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label-for="'description_' + (questionIndex+1)"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('elearning_tim.description_en') }}
                                      </template>
                                      <b-form-textarea
                                          rows="2"
                                          :id="'description_' + (questionIndex+1)"
                                          v-model="evaluationQuestion.description"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :placeholder="$t('elearning_tim.description_en')"
                                      ></b-form-textarea>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="6">
                                  <ValidationProvider name="Description Bn"  :vid="'description_bn_' + (questionIndex+1)" rules="">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label-for="'description_bn_' + (questionIndex+1)"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{$t('elearning_tim.description_bn')}}
                                      </template>
                                      <b-form-textarea
                                          rows="2"
                                          :id="'description_bn_' + (questionIndex+1)"
                                          v-model="evaluationQuestion.description_bn"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :placeholder="$t('elearning_tim.description_bn')"
                                      ></b-form-textarea>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col lg="6" sm="6">
                                  <ValidationProvider name="Marks"  :vid="'marks_' + (questionIndex+1)" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label-for="'marks_' + (questionIndex+1)"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('elearning_tim.marks') }} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                          type="number"
                                          :id="'marks_' + (questionIndex+1)"
                                          v-model="evaluationQuestion.marks"
                                          @input="getTotalMark()"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :placeholder="$t('elearning_tim.marks')"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="6">
                                  <ValidationProvider name="Attachment" :vid="'attachment_'+questionIndex">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label-for="'attachment_'+questionIndex"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('globalTrans.attachment') }}
                                      </template>
                                      <b-form-file
                                          :id="'attachment_'+questionIndex"
                                          :ref="'attachment_'+questionIndex"
                                          v-model="evaluationQuestion.attachment"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-file>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                      <a v-if="id && evaluationQuestion.attachment" class="btn btn-sm btn-success mt-2" target='_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/' + evaluationQuestion.attachment" download><i class="fas fa-download"></i> {{$t('globalTrans.view_download')}}</a>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col lg="12">
                                  <table class="table table-bordered table-striped" v-if="evaluationQuestion.question_type === 1">
                                    <thead class="thead">
                                    <tr>
                                      <th style="width: 10%;">{{ $t('globalTrans.sl_no') }}</th>
                                      <th style="width: 10%;">{{ $t('elearning_tim.is_correct') }} <span class="text-danger">*</span></th>
                                      <th>{{ $t('elearning_tim.option_en') }} <span class="text-danger">*</span></th>
                                      <th>{{ $t('elearning_tim.option_bn') }} <span class="text-danger">*</span></th>
                                      <!-- <th>{{ $t('globalTrans.attachment') }}</th> -->
                                    </tr>
                                    </thead>
                                    <tr v-for="(evaluationOption, optionIndex) in evaluationQuestion.evaluation_options" :key="(questionIndex+1)+(optionIndex+1)">
                                      <td class="text-center">{{ $i18n.locale === 'en' ? String.fromCharCode(97 + optionIndex) : String.fromCharCode(0x0995 + optionIndex) }}</td>
                                      <td class="text-center align-middle">
                                        <ValidationProvider name="Answer"  :vid="'is_correct_' + (questionIndex+1) + '-' + (optionIndex + 1)" rules="">
                                          <div
                                              slot-scope="{ valid, errors }"
                                          >
                                          <b-form-checkbox
                                            :id="'is_correct_' + (questionIndex + 1) + '_' + (optionIndex + 1)"
                                            v-model="evaluationOption.is_correct"
                                            @change="getCorrectCheck(evaluationQuestion.evaluation_options, optionIndex)"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :name="'is_correct_' + (questionIndex + 1) + '_' + (optionIndex + 1)"
                                            value="1"
                                            unchecked-value="0"
                                          >
                                          </b-form-checkbox>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </div>
                                        </ValidationProvider>
                                      </td>
                                      <td>
                                        <ValidationProvider name="Option En"  :vid="'option_en_' + (questionIndex+1) + '-' + (optionIndex + 1)" rules="required">
                                          <div
                                              slot-scope="{ valid, errors }"
                                          >
                                            <b-form-input
                                                :id="'option_en_' + (questionIndex+1) + '-' + (optionIndex + 1)"
                                                v-model="evaluationOption.option_en"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :placeholder="$t('elearning_tim.option_en')"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </div>
                                        </ValidationProvider>
                                      </td>
                                      <td>
                                        <ValidationProvider name="Option Bn"  :vid="'option_bn_' + (questionIndex+1) + '-' + (optionIndex + 1)" rules="required">
                                          <div
                                              slot-scope="{ valid, errors }"
                                          >
                                            <b-form-input
                                                :id="'option_bn_' + (questionIndex+1) + '-' + (optionIndex + 1)"
                                                v-model="evaluationOption.option_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :placeholder="$t('elearning_tim.option_bn')"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </div>
                                        </ValidationProvider>
                                      </td>
                                      <!-- <td>
                                        <ValidationProvider name="Attachment" :vid="'option_attachment_'+(questionIndex+1)">
                                          <div
                                              slot-scope="{ valid, errors }"
                                          >
                                            <b-form-file
                                                :id="'option_attachment_'+(questionIndex+1) + '_' + (optionIndex + 1)"
                                                :ref="'option_attachment_'+(questionIndex+1) + '_' + (optionIndex + 1)"
                                                v-model="evaluationOption.option_attachment"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-file>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                            <a v-if="id && evaluationOption.option_attachment" class="btn btn-sm btn-success mt-2" target='_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/' + evaluationOption.option_attachment" download><i class="fas fa-download"></i> {{$t('globalTrans.view_download')}}</a>
                                          </div>
                                        </ValidationProvider>
                                      </td> -->
                                    </tr>
                                  </table>
                                  <table class="table table-bordered table-striped" v-if="evaluationQuestion.question_type === 3">
                                    <thead class="thead">
                                    <tr>
                                      <th style="width: 10%;">{{ $t('globalTrans.sl_no') }}</th>
                                      <th style="width: 10%;">{{ $t('elearning_tim.is_correct') }}</th>
                                      <th>{{ $t('elearning_tim.option_en') }}</th>
                                      <th>{{ $t('elearning_tim.option_bn') }}</th>
                                      <!-- <th>{{ $t('globalTrans.attachment') }}</th> -->
                                    </tr>
                                    </thead>
                                    <tr v-for="(evaluationOption, optionIndex) in evaluationQuestion.evaluation_options" :key="(questionIndex+1)+(optionIndex+1)">
                                      <td class="text-center">{{ $i18n.locale === 'en' ? String.fromCharCode(97 + optionIndex) : String.fromCharCode(0x0995 + optionIndex) }}</td>
                                      <td class="text-center align-middle">
                                        <ValidationProvider name="Answer"  :vid="'is_correct_' + (questionIndex+1) + '-' + (optionIndex + 1)" rules="required">
                                          <div
                                            slot-scope="{ valid, errors }"
                                          >
                                          <b-form-radio
                                            v-model="evaluationOption.is_correct"
                                            @change="checkUncheckValue(evaluationQuestion.evaluation_options, optionIndex)"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :id="'is_correct_' + (questionIndex + 1) + '_' + (optionIndex + 1)"
                                            :name="'is_correct_' + (questionIndex + 1) + '_' + (optionIndex + 1)"
                                            :value="1"
                                          >
                                          </b-form-radio>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </div>
                                        </ValidationProvider>
                                      </td>
                                      <td>
                                        <ValidationProvider name="Option En"  :vid="'option_en_' + (questionIndex+1) + '-' + (optionIndex + 1)" rules="required">
                                          <div
                                              slot-scope="{ valid, errors }"
                                          >
                                            <b-form-input
                                                :id="'option_en_' + (questionIndex+1) + '-' + (optionIndex + 1)"
                                                v-model="evaluationOption.option_en"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :placeholder="$t('elearning_tim.option_en')"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </div>
                                        </ValidationProvider>
                                      </td>
                                      <td>
                                        <ValidationProvider name="Option Bn"  :vid="'option_bn_' + (questionIndex+1) + '-' + (optionIndex + 1)" rules="required">
                                          <div
                                              slot-scope="{ valid, errors }"
                                          >
                                            <b-form-input
                                                :id="'option_bn_' + (questionIndex+1) + '-' + (optionIndex + 1)"
                                                v-model="evaluationOption.option_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :placeholder="$t('elearning_tim.option_bn')"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </div>
                                        </ValidationProvider>
                                      </td>
                                      <!-- <td>
                                        <ValidationProvider name="Attachment" :vid="'option_attachment_'+(questionIndex+1)">
                                          <div
                                              slot-scope="{ valid, errors }"
                                          >
                                            <b-form-file
                                                :id="'option_attachment_'+(questionIndex+1) + '_' + (optionIndex + 1)"
                                                :ref="'option_attachment_'+(questionIndex+1) + '_' + (optionIndex + 1)"
                                                v-model="evaluationOption.option_attachment"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-file>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                            <a v-if="id && evaluationOption.option_attachment" class="btn btn-sm btn-success mt-2" target='_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/' + evaluationOption.option_attachment" download><i class="fas fa-download"></i> {{$t('globalTrans.view_download')}}</a>
                                          </div>
                                        </ValidationProvider>
                                      </td> -->
                                    </tr>
                                  </table>
                                  <table class="table table-bordered table-striped" v-if="evaluationQuestion.question_type === 2">
                                    <thead class="thead">
                                    <tr>
                                      <th style="width: 10%;">{{ $t('globalTrans.sl_no') }}</th>
                                      <th style="width: 10%;">{{ $t('elearning_tim.is_correct') }}</th>
                                      <th>{{ $t('elearning_tim.option_en') }}</th>
                                      <th>{{ $t('elearning_tim.option_bn') }}</th>
                                      <!-- <th>{{ $t('globalTrans.attachment') }}</th> -->
                                    </tr>
                                    </thead>
                                    <tr v-for="(evaluationOption, optionIndex) in evaluationQuestion.evaluation_options.slice(0, 2)" :key="(questionIndex+1)+(optionIndex+1)">
                                      <td class="text-center">{{ $i18n.locale === 'en' ? String.fromCharCode(97 + optionIndex) : String.fromCharCode(0x0995 + optionIndex) }}</td>
                                      <td class="text-center align-middle">
                                        <ValidationProvider name="Answer"  :vid="'is_correct_' + (questionIndex+1) + '-' + (optionIndex + 1)" rules="required">
                                          <div
                                            slot-scope="{ valid, errors }"
                                          >
                                          <b-form-radio
                                            v-model="evaluationOption.is_correct"
                                            @change="checkUncheckValue(evaluationQuestion.evaluation_options, optionIndex)"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :id="'is_correct_' + (questionIndex + 1) + '_' + (optionIndex + 1)"
                                            :name="'is_correct_' + (questionIndex + 1) + '_' + (optionIndex + 1)"
                                            :value="1"
                                          >
                                          </b-form-radio>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </div>
                                        </ValidationProvider>
                                      </td>
                                      <td>
                                        <ValidationProvider name="Option En"  :vid="'option_en_' + (questionIndex+1) + '-' + (optionIndex + 1)" rules="required">
                                          <div
                                              slot-scope="{ valid, errors }"
                                          >
                                            <b-form-input
                                                :id="'option_en_' + (questionIndex+1) + '-' + (optionIndex + 1)"
                                                v-model="evaluationOption.option_en"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :placeholder="$t('elearning_tim.option_en')"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </div>
                                        </ValidationProvider>
                                      </td>
                                      <td>
                                        <ValidationProvider name="Option Bn"  :vid="'option_bn_' + (questionIndex+1) + '-' + (optionIndex + 1)" rules="required">
                                          <div
                                              slot-scope="{ valid, errors }"
                                          >
                                            <b-form-input
                                                :id="'option_bn_' + (questionIndex+1) + '-' + (optionIndex + 1)"
                                                v-model="evaluationOption.option_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :placeholder="$t('elearning_tim.option_bn')"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </div>
                                        </ValidationProvider>
                                      </td>
                                      <!-- <td>
                                        <ValidationProvider name="Attachment" :vid="'option_attachment_'+(questionIndex+1)">
                                          <div
                                              slot-scope="{ valid, errors }"
                                          >
                                            <b-form-file
                                                :id="'option_attachment_'+(questionIndex+1) + '_' + (optionIndex + 1)"
                                                :ref="'option_attachment_'+(questionIndex+1) + '_' + (optionIndex + 1)"
                                                v-model="evaluationOption.option_attachment"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-file>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                            <a v-if="id && evaluationOption.option_attachment" class="btn btn-sm btn-success mt-2" target='_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/' + evaluationOption.option_attachment" download><i class="fas fa-download"></i> {{$t('globalTrans.view_download')}}</a>
                                          </div>
                                        </ValidationProvider>
                                      </td> -->
                                    </tr>
                                  </table>
                                  <div class="mt-3">
                                    <button @click="addItem" class="btn btn-sm btn-primary mr-2" type="button"
                                    ><i class="fas fa-plus-circle m-0"></i> {{$t('globalTrans.add_more')}}</button>
                                    <button @click="remove(questionIndex)" class="btn btn-sm btn-danger" type="button" v-if="formData.evaluation_questions.length > 1"><i class="fas fa-window-close m-0"></i> {{ $t('globalTrans.delete') }}</button>
                                  </div>
                                </b-col>
                                <!-- <b-col lg="12" v-if="evaluationQuestion.question_type === 2">
                                  <table class="table table-bordered table-striped" v-if="evaluationQuestion.question_type === 2">
                                    <thead class="thead">
                                    <tr>
                                      <th>{{ $t('globalTrans.sl_no') }}</th>
                                      <th>{{ $t('elearning_tim.is_correct') }}</th>
                                      <th>{{ $t('elearning_tim.option_en') }}</th>
                                      <th>{{ $t('elearning_tim.option_bn') }}</th>
                                    </tr>
                                    </thead>
                                    <tr v-for="(evaluationOption, optionIndex) in evaluationQuestion.evaluation_options2" :key="(questionIndex+1)+(optionIndex+1)">
                                      <td>{{ String.fromCharCode(97 + optionIndex) }} {{ String.fromCharCode(0x0995 + optionIndex) }}</td>
                                      <td class="text-center align-middle">
                                        <ValidationProvider name="Answer"  :vid="'is_correct_' + (questionIndex+1) + '-' + (optionIndex + 1)" rules="required">
                                          <div
                                            slot-scope="{ valid, errors }"
                                          >
                                          <b-form-radio
                                            v-model="evaluationOption.is_correct"
                                            @change="checkUncheckValue(evaluationQuestion.evaluation_options2, optionIndex)"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :id="'is_correct_' + (questionIndex + 1) + '_' + (optionIndex + 1)"
                                            :name="'is_correct_' + (questionIndex + 1) + '_' + (optionIndex + 1)"
                                            :value="1"
                                          >
                                          </b-form-radio>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </div>
                                        </ValidationProvider>
                                      </td>
                                      <td>
                                        <ValidationProvider name="Option En"  :vid="'option_en_' + (questionIndex+1) + '-' + (optionIndex + 1)" rules="required">
                                          <div
                                              slot-scope="{ valid, errors }"
                                          >
                                            <b-form-input
                                                :id="'option_en_' + (questionIndex+1) + '-' + (optionIndex + 1)"
                                                v-model="evaluationOption.option_en"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :placeholder="$t('elearning_tim.option_en')"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </div>
                                        </ValidationProvider>
                                      </td>
                                      <td>
                                        <ValidationProvider name="Option Bn"  :vid="'option_bn_' + (questionIndex+1) + '-' + (optionIndex + 1)" rules="required">
                                          <div
                                              slot-scope="{ valid, errors }"
                                          >
                                            <b-form-input
                                                :id="'option_bn_' + (questionIndex+1) + '-' + (optionIndex + 1)"
                                                v-model="evaluationOption.option_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :placeholder="$t('elearning_tim.option_bn')"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </div>
                                        </ValidationProvider>
                                      </td>
                                    </tr>
                                  </table>
                                  <div class="mt-3">
                                    <button @click="evaluationQuestion.question_type === 2 ? addItem3 : addItem" class="btn btn-sm btn-primary mr-2" type="button"
                                    ><i class="fas fa-plus-circle m-0"></i> {{$t('globalTrans.add_more')}}</button>
                                    <button @click="evaluationQuestion.question_type === 2 ? remove3(questionIndex) : remove(questionIndex)" class="btn btn-sm btn-danger" type="button" v-if="formData.evaluation_questions.length > 1"><i class="fas fa-window-close m-0"></i> {{ $t('globalTrans.delete') }}</button>
                                  </div>
                                </b-col> -->
                              </b-row>
                            </div>

                          </fieldset>
                        </div>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
        <!-- <pre>{{ formData }}</pre> -->
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
/* .checkboxes label.custom-control-label {
    color: black !important;
    font-size: 13px !important;
    font-weight: bold !important;
} */
select#profession_type {
    text-transform: none !important;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { evaluationStore, evaluationUpdate, evaluationShow, circularList, preEvaluationData } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.$route.query.id) {
      this.getFormData()
    }
    this.formData = Object.assign({}, this.formData, {
      fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
    })
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.formData.org_id = this.$store.state.Auth.authUser.org_id
      if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
        this.formData.coordinator_id = this.$store.state.Auth.authUser.user_id
      }
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      id: this.$route.query.id,
      correctCheck: '',
      itemShow: true,
      itemShowDis: false,
      loading: false,
      circularLoading: false,
      formData: {
        coordinator_id: 0,
        same_pre_evaluation: 0,
        id: '',
        exam_type: 1,
        exam_date: '',
        circular_memo_no: '',
        batch_no: '',
        fiscal_year_id: null,
        org_id: null,
        training_category_id: null,
        training_type_id: null,
        training_title_id: null,
        status: 1,
        exam_time_start: '',
        exam_time_end: '',
        total_mark: 0,
        evaluation_questions: [
          {
            question_type: 1,
            question: '',
            question_bn: '',
            description: '',
            description_bn: '',
            attachment: null,
            marks: '',
            evaluation_options: [
              {
                option_en: '',
                option_bn: '',
                option_attachment: null,
                is_correct: ''
              },
              {
                option_en: '',
                option_bn: '',
                option_attachment: null,
                is_correct: ''
              },
              {
                option_en: '',
                option_bn: '',
                option_attachment: null,
                is_correct: ''
              },
              {
                option_en: '',
                option_bn: '',
                option_attachment: null,
                is_correct: ''
              }
            ],
            evaluation_options2: [
              {
                option_en: 'True',
                option_bn: 'সত্য',
                is_correct: ''
              },
              {
                option_en: 'False',
                option_bn: 'মিথ্যা',
                is_correct: ''
              }
            ]
          }
        ]
      },
      batchList: [],
      allBatchListData: [],
      circularList: [],
      trainingCategoryList: [],
      trainingTypeList: [],
      attachments_errors: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: [],
      myFilter: (option, text, search) => {
                const temp = search.toLowerCase()
                return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                option.text_bn.toLowerCase().indexOf(temp) > -1
            }
    }
  },
  computed: {
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    },
    saveBtnName: function () {
      return this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.submit')
    },
    examTypeList: function () {
      return [
        { text: this.currentLocale === 'en' ? 'Pre-Evaluation' : 'প্রি-ইভালুয়েশন', value: 1 },
        { text: this.currentLocale === 'en' ? 'Post-Evaluation' : 'পোস্ট-ইভালুয়েশন', value: 2 }
      ]
    },
    questionTypeList: function () {
      return [
        { text: this.currentLocale === 'en' ? 'MCQ' : 'এমসিকিউ', value: 1 },
        { text: this.currentLocale === 'en' ? 'True Or False' : 'সত্য অথবা মিথ্যা', value: 2 },
        { text: this.currentLocale === 'en' ? 'Fill in the Blanks' : 'শূন্যস্থান পূরণ', value: 3 }
      ]
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    'formData.fiscal_year_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        // this.formData.circular_memo_no = ''
        // this.formData.batch_no = 0
        this.getCircularList()
      }
    },
    'formData.org_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        // this.formData.training_type_id = 0
        this.trainingTypeList = this.getTypeList(newValue)
      } else {
        this.trainingTypeList = []
      }
    },
    'formData.training_type_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        // this.formData.training_category_id = 0
        this.trainingCategoryList = this.getCategoryList(newValue)
      } else {
        this.trainingCategoryList = []
      }
    },
    'formData.training_category_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        // this.formData.training_title_id = 0
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      } else {
        this.trainingTitleList = []
      }
    },
    'formData.training_title_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        // this.formData.circular_memo_no = ''
        // this.formData.batch_no = 0
        this.getCircularList()
      }
    },
    'formData.circular_memo_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => String(allBatchListData.circular_memo_no) === String(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.formData.batch_no = allBatchListData.batch_no
        }
      }
    },
    'formData.batch_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.formData.circular_memo_no = allBatchListData.circular_memo_no
        }
      }
    },
    'formData.same_pre_evaluation': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getPreData(newVal)
      }
    }
  },
  methods: {
    async getPreData (data) {
      if (parseInt(data) === 1) {
        this.loading = true
        const params = {
          org_id: this.formData.org_id,
          circular_memo_no: this.formData.circular_memo_no,
          fiscal_year_id: this.formData.fiscal_year_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, preEvaluationData, params)
        if (!result.success) {
          this.formData = {
            same_pre_evaluation: 0,
            id: '',
            exam_type: 2,
            exam_date: '',
            circular_memo_no: '',
            batch_no: '',
            fiscal_year_id: 0,
            org_id: 0,
            training_category_id: 0,
            training_type_id: 0,
            training_title_id: 0,
            status: 1,
            exam_time_start: '',
            exam_time_end: '',
            total_mark: 0,
            evaluation_questions: [
              {
                question_type: 1,
                question: '',
                question_bn: '',
                description: '',
                description_bn: '',
                attachment: null,
                marks: '',
                evaluation_options: [
                  {
                    option_en: '',
                    option_bn: '',
                    option_attachment: null,
                    is_correct: ''
                  },
                  {
                    option_en: '',
                    option_bn: '',
                    option_attachment: null,
                    is_correct: ''
                  },
                  {
                    option_en: '',
                    option_bn: '',
                    option_attachment: null,
                    is_correct: ''
                  },
                  {
                    option_en: '',
                    option_bn: '',
                    option_attachment: null,
                    is_correct: ''
                  }
                ],
                evaluation_options2: [
                  {
                    option_en: 'True',
                    option_bn: 'সত্য',
                    is_correct: ''
                  },
                  {
                    option_en: 'False',
                    option_bn: 'মিথ্যা',
                    is_correct: ''
                  }
                ]
              }
            ]
          }
          this.loading = false
        } else {
          this.formData = result.data
          this.formData.id = ''
          this.formData.exam_type = 2
          this.formData.same_pre_evaluation = 1
        }
        this.loading = false
      }
    },
    getCorrectCheck (data, selectedIndex) {
      for (let i = 0; i < data.length; i++) {
        const isValid = data.find(option => option.is_correct === 1 && i === selectedIndex)
        if (isValid) {
          this.correctCheck = 'required|min_value:1'
        } else {
          this.correctCheck = 'required|min_value:1'
        }
      }
    },
    checkUncheckValue (options, selectedIndex) {
      for (let i = 0; i < options.length; i++) {
        if (i === selectedIndex) {
          options[i].is_correct = 1
        } else {
          options[i].is_correct = 0
        }
      }
    },
    getTotalMark () {
      let mark = 0
      this.formData.evaluation_questions.map((obj, index) => {
        mark += parseInt(obj.marks)
      })
      this.formData.total_mark = mark
    },
    async getCircularList () {
      if (this.formData.fiscal_year_id && this.formData.training_title_id) {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.formData.fiscal_year_id,
          training_type_id: this.formData.training_type_id,
          training_category_id: this.formData.training_category_id,
          training_title_id: this.formData.training_title_id,
          coordinator_id: this.formData.coordinator_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
          this.allBatchListData = result.batchList
          this.batchInfo()
          this.circluarList()
        }
        this.circularLoading = false
      }
    },
    batchInfo () {
      const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.formData.training_title_id))
      const batchList = []
      batchData.map((obj, index) => {
        if (obj.batch_no) {
          const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
          if (typeof isThere !== 'undefined') {
            batchList.push(isThere)
          }
        }
      })
      this.batchList = batchList
      if (this.batchList.length === 1) {
        this.formData.batch_no = 1
      }
    },
    circluarList () {
      const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.formData.training_title_id))
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        } else {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        }
      })
      this.circularList = tmpList
    },
    getTypeList (orgId) {
      if (this.formData.org_id) {
        const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (orgId) {
          return type.filter(item => item.org_id === parseInt(orgId))
        }
        return type
      }
    },
    getCategoryList (typeId) {
      if (this.formData.training_type_id) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      }
    },
    getTrainingTitleList (categoryId) {
      // if (this.formData.training_category_id) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      // }
    },
    async getFormData () {
      this.loading = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, evaluationShow + '/' + this.$route.query.id)
      if (!result.success) {
        this.formData = []
        this.loading = false
      } else {
        this.formData = result.data
        if (result.data.status === 2) {
          this.itemShow = false
          this.itemShowDis = true
        }
        this.loading = false
      }
    },
    async createData () {
      this.loading = true
      let result = null
      const key1 = parseInt(this.formData.evaluation_questions.length - 1)
      const item = this.formData.evaluation_questions[key1]
      let isEmpty = false
      // check whether form fields are filled
      Object.keys(item).map(key => {
        if (['question_type', 'question', 'question_bn', 'marks'].includes(key) && (item[key] === '' || item[key] === 0)) {
          isEmpty = true
        }
        if (item.question_type === 1) {
          isEmpty = true
          item.evaluation_options.filter(evaluationOption => {
            if (evaluationOption.option_en && evaluationOption.option_bn && evaluationOption.is_correct) {
              isEmpty = false
            }
          })
        }
      })
      if (isEmpty === false) {
        var formData = new FormData()
        Object.keys(this.formData).map(key => {
          if (key !== 'evaluation_questions') {
            formData.append(key, this.formData[key])
          } else {
            this.formData.evaluation_questions.filter((evaluationQuestion, questionIndex) => {
              if (evaluationQuestion.id) {
                formData.append('evaluation_questions[' + questionIndex + '][id]', evaluationQuestion.id)
              }
              formData.append('evaluation_questions[' + questionIndex + '][question_type]', evaluationQuestion.question_type)
              formData.append('evaluation_questions[' + questionIndex + '][question]', evaluationQuestion.question)
              formData.append('evaluation_questions[' + questionIndex + '][question_bn]', evaluationQuestion.question_bn)
              formData.append('evaluation_questions[' + questionIndex + '][description]', evaluationQuestion.description)
              formData.append('evaluation_questions[' + questionIndex + '][description_bn]', evaluationQuestion.description_bn)
              if (evaluationQuestion.attachment) {
                formData.append('evaluation_questions[' + questionIndex + '][attachment]', evaluationQuestion.attachment)
              }
              formData.append('evaluation_questions[' + questionIndex + '][marks]', evaluationQuestion.marks)
              evaluationQuestion.evaluation_options.filter((evaluationOption, optionIndex) => {
                if (evaluationOption.id) {
                  formData.append('evaluation_questions[' + questionIndex + '][evaluation_options][' + optionIndex + '][id]', evaluationOption.id)
                }
                formData.append('evaluation_questions[' + questionIndex + '][evaluation_options][' + optionIndex + '][option_en]', evaluationOption.option_en)
                formData.append('evaluation_questions[' + questionIndex + '][evaluation_options][' + optionIndex + '][option_bn]', evaluationOption.option_bn)
                if (evaluationOption.option_attachment) {
                  formData.append('evaluation_questions[' + questionIndex + '][evaluation_options][' + optionIndex + '][option_attachment]', evaluationOption.option_attachment)
                }
                formData.append('evaluation_questions[' + questionIndex + '][evaluation_options][' + optionIndex + '][is_correct]', evaluationOption.is_correct)
              })
            })
          }
        })
        if (this.formData.id) {
          formData.append('_method', 'PUT')
          result = await RestApi.postData(trainingElearningServiceBaseUrl, `${evaluationUpdate}/${this.formData.id}`, formData)
        } else {
          result = await RestApi.postData(trainingElearningServiceBaseUrl, evaluationStore, formData)
        }
      }
      this.loading = false
      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$router.push('/training-e-learning-service/tim/evaluation')
      } else {
        this.attachments_errors = result.errors
        this.$refs.form.setErrors(result.errors)
      }
    },
    addItem () {
      const obj = {
        question_type: 1,
        question: '',
        question_bn: '',
        description: '',
        description_bn: '',
        attachment: null,
        marks: '',
        evaluation_options: [
          {
            option_en: '',
            option_bn: '',
            option_attachment: null,
            is_correct: ''
          },
          {
            option_en: '',
            option_bn: '',
            option_attachment: null,
            is_correct: ''
          },
          {
            option_en: '',
            option_bn: '',
            option_attachment: null,
            is_correct: ''
          },
          {
            option_en: '',
            option_bn: '',
            option_attachment: null,
            is_correct: ''
          }
        ]
      }
      const key1 = parseInt(this.formData.evaluation_questions.length - 1)
      const item = this.formData.evaluation_questions[key1]
      let isEmpty = false
      // check whether form fields are filled
      Object.keys(item).map(key => {
        if (['question_type', 'question', 'question_bn', 'marks'].includes(key) && (item[key] === '' || item[key] === 0)) {
          isEmpty = true
        }
        if (item.question_type === 1) {
          isEmpty = true
          item.evaluation_options.filter(evaluationOption => {
            if (evaluationOption.option_en && evaluationOption.option_bn && evaluationOption.is_correct) {
              isEmpty = false
            }
          })
        }
      })
      if (isEmpty === false) {
        this.formData.evaluation_questions.push(obj)
      }
    },
    remove (key) {
      this.formData.evaluation_questions.splice(key, 1)
      this.getTotalMark()
    },
    addItem3 () {
      const obj = {
        question_type: 3,
        question: '',
        question_bn: '',
        description: '',
        description_bn: '',
        attachment: null,
        marks: '',
        evaluation_options2: [
          {
            option_en: '',
            option_bn: '',
            is_correct: ''
          },
          {
            option_en: '',
            option_bn: '',
            is_correct: ''
          }
        ]
      }
      const key1 = parseInt(this.formData.evaluation_questions.length - 1)
      const item = this.formData.evaluation_questions[key1]
      let isEmpty = false
      // check whether form fields are filled
      Object.keys(item).map(key => {
        if (['question_type', 'question', 'question_bn', 'marks'].includes(key) && (item[key] === '' || item[key] === 0)) {
          isEmpty = true
        }
        if (item.question_type === 1) {
          isEmpty = true
          item.evaluation_options2.filter(evaluationOption => {
            if ((evaluationOption.option_en && evaluationOption.option_bn) || (evaluationOption.option_attachment)) {
              isEmpty = false
            }
          })
        }
      })
      if (isEmpty === false) {
        this.formData.evaluation_questions.push(obj)
      }
    },
    remove3 (key) {
      this.formData.evaluation_questions.splice(key, 1)
    }
  }
}
</script>
